// import Item from "antd/lib/list/Item";
// import { useAgencyInfo } from "context/agency";
// import { useAppServices } from "hook/services";
import React from "react";
import { useEffect } from "react";
import getRandomSentence from "utils/getRandomSentence";
import { useState } from "react";
import axios from "axios";
import env from "config";
// import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

const Data = () => {
  const pathname = window.location.pathname;
  const loc_id = pathname.split("/")[1];
  const loc_name = decodeURI(pathname.split("/")[2]);
  const username = decodeURI(pathname.split("/")[3]);
  const BASE_URL = `${env.API_URL}/v1`;
  const [data, setdata] = useState([]);
  const [processing, setprocessing] = useState(true);
  const [error, seterror] = useState("");

  const GetChats = async () => {
    await axios
      .get(BASE_URL + "/chat/filter?locationId=" + loc_id)
      .then(async function (response) {
        console.log(response.data.data, "chats");
        console.log(data);
        setdata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ChatSubmit = async (payload) => {
    console.log(data, "payloadpayloadpayload");

    var response_data = [];
    const response = await axios
      .post(BASE_URL + "/chat", { ...payload })
      .then(async function (response) {
        console.log(response.data, "response");
        var chat_data = {};
        chat_data["Welcome " + response.data.data.usename] = [];
        const chat = {
          id: response.data.data._id,
          name: response.data.data.name,
          whatsapp_name: response.data.data.usename,
          messages: chat_data,
          welcome_array: "Welcome " + response.data.data.usename,
        };
        // console.log(data, "chatchat");

        const main_array = data;
        console.log(main_array, "main_array");
        main_array.unshift(chat);
        console.log(main_array, "main_array");
        response_data = main_array;
        setdata(main_array);
        return main_array;
      })
      .catch(function (error) {
        console.log(error, "error");
        return error;
      });
    return response;
  };
  const onLoad = () => {
    GetChats();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return {
    data: data,
    processing: processing,
    handleRefreshData: GetChats,
    ChatSubmit: ChatSubmit,
  };
};
export default Data;
