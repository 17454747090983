import Icon from "components/Icon";
import React from "react";
import media from "assets/images/women.jpeg";
import formatTime from "utils/formatTime";
import { useUsersContext } from "context/usersContext";

const Convo = ({
  lastMsgRef,
  messages: allMessages,
  userId,
  newMessage,
  setNewMessage,
  submitNewMessage,
}) => {
  const dates = Object.keys(allMessages);
  const { users, setUserAsUnread, addNewMessage } = useUsersContext();
  const SubmitPrompt = (user_msg, prompt) => {
    // alert("ksdjh");
    addNewMessage(userId, user_msg, prompt);
  };
  return dates.map((date, dateIndex) => {
    const messages = allMessages[date];
    return (
      <div key={dateIndex}>
        <div className="chat__date-wrapper">
          <span className="chat__date"> {date}</span>
        </div>
        {/* {dateIndex === 0 && (
					<p className="chat__encryption-msg">
						<Icon id="lock" className="chat__encryption-icon" />
						Messages are end-to-end encrypted. No one outside of this chat, not
						even WhatsApp, can read or listen to them. Click to learn more.
					</p>
				)} */}
        {/* {messages.length == 0 ? (
          <>
            <div
              className="container"
              style={{ position: "fixed", bottom: "100px", zIndex: "999" }}
            >
              <div className="row">
                <div
                  className="offset-1 col-md-4  prompt_box"
                  onClick={() =>
                    SubmitPrompt(
                      "What should we post this week?",
                      "Create multiple unique posts for each platform. Emphasise lengthy captions, use our tone of voice, emotions, emojis, and blend existing hashtags with new ones. Use our previous content to inspire ideas for new content that will engage our audience."
                    )
                  }
                >
                  <div className="prompt_text">
                    <b>What should we post this week?</b>
                  </div>
                  <Icon id="send" className="chat__input-icon prompt_icon" />
                </div>
                <div
                  className="col-md-4  prompt_box"
                  onClick={() =>
                    SubmitPrompt(
                      "What have been our best posts?",
                      "Analyse and highlight all our top-performing posts, emphasising their success, and present a curated list listed by platform and with links to each of the posts?"
                    )
                  }
                >
                  <div className="prompt_text">
                    <b>What have been our best posts?</b>
                  </div>
                  <Icon id="send" className="chat__input-icon prompt_icon" />
                </div>
                <div
                  className="offset-1 col-md-4  prompt_box"
                  onClick={() =>
                    SubmitPrompt(
                      "Inspire me!",
                      "With our brand's tone of voice in mind, review past content. List forthcoming national days and holidays. Suggest lengthy, captivating posts with emojis, engaging questions, and relevant hashtags."
                    )
                  }
                >
                  <div className="prompt_text">
                    <b>Inspire me!</b>
                  </div>
                  <Icon id="send" className="chat__input-icon prompt_icon" />
                </div>
                <div
                  className="col-md-4  prompt_box"
                  onClick={() =>
                    SubmitPrompt(
                      "What hashtags should we use?",
                      "Analyse our content and recommend a variety of hashtags that effectively represent our brand and are likely to engage new audiences."
                    )
                  }
                >
                  <div className="prompt_text">
                    <b>What hashtags should we use?</b>
                  </div>
                  <Icon id="send" className="chat__input-icon prompt_icon" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )} */}
        <div className="chat__msg-group">
          {messages.map((message, msgIndex) => {
            const assignRef = () =>
              dateIndex === dates.length - 1 && msgIndex === messages.length - 1
                ? lastMsgRef
                : undefined;
            return (
              <>
                {message.image ? (
                  <div
                    className={`chat__msg chat__img-wrapper ${
                      message.sender ? "chat__msg--rxd" : "chat__msg--sent"
                    }`}
                    ref={assignRef()}
                  >
                    <img src={media} alt="" className="chat__img" />
                    <span className="chat__msg-footer">
                      <span>{formatTime(message.time)}</span>
                      {!message.sender && (
                        <Icon
                          id={
                            message?.status === "sent"
                              ? "singleTick"
                              : "doubleTick"
                          }
                          aria-label={message?.status}
                          className={`chat__msg-status-icon ${
                            message?.status === "read"
                              ? "chat__msg-status-icon--blue"
                              : ""
                          }`}
                        />
                      )}
                    </span>

                    <button
                      aria-label="Message options"
                      className="chat__msg-options"
                    >
                      <Icon id="downArrow" className="chat__msg-options-icon" />
                    </button>
                  </div>
                ) : message.sender ? (
                  <p className="chat__msg chat__msg--rxd" ref={assignRef()}>
                    <span>
                      <div
                        dangerouslySetInnerHTML={{ __html: message.content }}
                      />
                    </span>
                    <span className="chat__msg-filler"> </span>
                    <span className="chat__msg-footer">
                      {formatTime(message.time)}
                    </span>
                    <button
                      aria-label="Message options"
                      className="chat__msg-options"
                    >
                      <Icon id="downArrow" className="chat__msg-options-icon" />
                    </button>
                  </p>
                ) : (
                  <p className="chat__msg chat__msg--sent" ref={assignRef()}>
                    <span>{message.content}</span>
                    <span className="chat__msg-filler"> </span>
                    <span className="chat__msg-footer">
                      <span> {formatTime(message.time)} </span>
                      <Icon
                        id={
                          message?.status === "sent"
                            ? "singleTick"
                            : "doubleTick"
                        }
                        aria-label={message?.status}
                        className={`chat__msg-status-icon ${
                          message?.status === "read"
                            ? "chat__msg-status-icon--blue"
                            : ""
                        }`}
                      />
                    </span>
                    <button
                      aria-label="Message options"
                      className="chat__msg-options"
                    >
                      <Icon id="downArrow" className="chat__msg-options-icon" />
                    </button>
                  </p>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  });
};

export default Convo;
