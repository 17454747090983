import React from "react";
import "./styles/main.css";
import maybe from "assets/images/maybe.png";
import axios from "axios";
import { useUsersContext } from "context/usersContext";
const Home = () => {
  const darkTheme = document.body.classList.contains("dark-theme");
  const { users: contacts, locValid, setlocValid } = useUsersContext();
  const pathname = window.location.pathname;
  const loc_id = pathname.split("/")[1];
  const loc_name = decodeURI(pathname.split("/")[2]);
  const username = decodeURI(pathname.split("/")[3]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(e.target.facebook.value);

    var payload = {
      facebook: [e.target.facebook.value],
      instagram: [e.target.instagram.value],
      profile_id: "v2/" + loc_id,
      profile_name: loc_name,
    };
    const response = await axios
      .post(
        "https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response.data, "response");
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error, "errror");
      });
  };
  return (
    <div className="home">
      <div className="home__img-wrapper">
        {/* <img src={darkTheme ? maybe : maybe} alt="" className="home__img" /> */}
        <h1 className="home__title">
          {" "}
          Do your best work faster with your own personalised AI
        </h1>
      </div>
    </div>
  );
};

export default Home;
