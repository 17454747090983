import React, { useState } from "react";
import "./style.css";
import Icon from "components/Icon";
import axios from "axios";
import env from "config";
import Data from "data/contacts";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";

function ChatModal() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [processinng, setprocessinng] = useState(false);

  const { users, setUserAsUnread, addNewMessage, refresh } = useUsersContext();
  const { ChatSubmit } = Data();
  const BASE_URL = `${env.API_URL}/v1`;

  const { pathname } = useLocation();
  const loc_id = pathname.split("/")[1];
  const loc_name = pathname.split("/")[2];
  const username = pathname.split("/")[3];
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const payload = {
      // name: name,
      locationId: loc_id,
      usename: username,
    };
    console.log(payload);
    const temp = await ChatSubmit(payload);
    console.log(temp[0].id, "submitdatasubmitdatasubmitdata");
    // toggleMenu();
    refresh(temp);

    // // Get the current pathname
    const currentPathname = history.location.pathname;
    const searchString = "/chat/";
    const chatUrl = `/${loc_id}/${loc_name}/${username}/chat/${temp[0].id}`;
    setUserAsUnread(temp[0].id);
    history.push(chatUrl);
    // if (currentPathname.includes(searchString)) {
    //   history.replace(temp[0].id);
    // } else {
    //   history.replace(temp[0].whatsapp_name+'/chat/'+temp[0].id);
    // }
  };

  return (
    <div className="chat-dropdown">
      <button
        className="btn btn-chat btn-sm"
        aria-label="New chat"
        onClick={handleSubmit}
      >
        New Chat
      </button>
      {/* {isOpen && (
        <div className="chat-dropdown-content">
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="chatName">Chat Name</label>
              <input
                type="text"
                class="form-control"
                name="name"
                id="chatName"
              />
            </div>
            <button type="submit" class="btn btn-chat">
              Submit
            </button>
          </form>
        </div>
      )} */}
    </div>
  );
}

export default ChatModal;
