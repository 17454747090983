import React from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useLocation } from "react-router-dom";
const Contact = ({ contact }) => {
  const { setUserAsUnread } = useUsersContext();
  // let parameter = useParams();
  const { pathname } = useLocation();
  const loc_id = pathname.split("/")[1];
  const loc_name = pathname.split("/")[2];
  const username = pathname.split("/")[3];
  // console.log(loc_id, "parameterparameterparameter");
  const getLastMessage = () => {
    const messageDates = Object.keys(contact.messages);
    const recentMessageDate = messageDates[messageDates.length - 1];
    const messages = [...contact.messages[recentMessageDate]];
    const lastMessage = messages.pop();
    return lastMessage;
  };

  const lastMessage = getLastMessage(contact);

  return (
    <Link
      className="sidebar-contact"
      to={`/${loc_id}/${loc_name}/${username}/chat/${contact.id}`}
      onClick={() => setUserAsUnread(contact.id)}
    >
      {/* <div className="sidebar-contact__avatar-wrapper">
        <img
          src={contact.profile_picture}
          alt={contact.profile_picture}
          className="avatar"
        />
      </div> */}
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name"> {contact.name} </h2>
          <span className="sidebar-contact__time">
            <div className="sidebar-contact__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
              </svg>
              {contact.whatsapp_name}
            </div>
          </span>
        </div>
        <div className="sidebar-contact__bottom-content">
          <p className="sidebar-contact__message-wrapper">
            {lastMessage?.status && (
              <Icon
                id={
                  lastMessage?.status === "sent" ? "singleTick" : "doubleTick"
                }
                aria-label={lastMessage?.status}
                className={`sidebar-contact__message-icon ${
                  lastMessage?.status === "read"
                    ? "sidebar-contact__message-icon--blue"
                    : ""
                }`}
              />
            )}
            <span
              className={`sidebar-contact__message ${
                !!contact.unread ? "sidebar-contact__message--unread" : ""
              }`}
            >
              <div dangerouslySetInnerHTML={{ __html: lastMessage?.content }} />
              {/* {contact.typing ? <i> typing...</i> : lastMessage?.content} */}
            </span>
          </p>
          <div className="sidebar-contact__icons">
            {contact.pinned && (
              // <Icon id="pinned" className="sidebar-contact__icon" />
              <></>
            )}
            {!!contact.unread && (
              <span className="sidebar-contact__unread">{contact.unread}</span>
            )}
            {/* <button aria-label="sidebar-contact__btn">
              <Icon
                id="downArrow"
                className="sidebar-contact__icon sidebar-contact__icon--dropdown"
              />
            </button> */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Contact;
