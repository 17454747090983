import { createContext, useContext, useEffect, useState } from "react";
import contacts from "data/contacts";
// import { useSocketContext } from "./socketContext";
import getRandomSentence from "utils/getRandomSentence";
import axios from "axios";
import env from "config";
const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);
const BASE_URL = `${env.API_URL}/v1`;

const UsersProvider = ({ children }) => {
  // const socket = useSocketContext();
  const { data, processing, handleRefreshData } = contacts();
  // console.log(data, processing, "hamza");
  const [users, setUsers] = useState(data);
  const [locValid, setlocValid] = useState(data);

  const pathname = window.location.pathname;
  const loc_id = pathname.split("/")[1];
  const loc_name = decodeURI(pathname.split("/")[2]);
  const username = decodeURI(pathname.split("/")[3]);
  const _updateUserProp = (userId, prop, value) => {
    setUsers((users) => {
      const usersCopy = [...users];
      let userIndex = users.findIndex((user) => user.id === userId);
      const userObject = usersCopy[userIndex];
      usersCopy[userIndex] = { ...userObject, [prop]: value };
      return usersCopy;
    });
  };

  const setUserAsTyping = (data) => {
    const { userId } = data;
    _updateUserProp(userId, "typing", true);
  };

  const setUserAsNotTyping = (data) => {
    const { userId } = data;
    _updateUserProp(userId, "typing", false);
  };

  const fetchMessageResponse = async (data) => {
    // setUsers((users) => {
    const { userId, response } = data;
    console.log(data, "datadata");
    // let msg_data = JSON.stringify({
    //   conversation_id: userId,
    //   user_name: username,
    //   prompt: response.content,
    // });
    var data = JSON.stringify({
      model: "gpt-3.5-turbo",
      n: 1,
      messages: [
        {
          role: "user",
          content: response.content,
        },
      ],
    });
    // console.log(msg_data, "msg_data");

    var config = {
      method: "post",
      url: "https://api.openai.com/v1/chat/completions",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 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`,
      },
      data: data,
    };
    var msg = "";
    await axios
      .request(config)
      .then((response) => {
        msg = response.data.choices[0].message.content;
        // console.log(response.data, "kjsdf");
      })
      .catch((error) => {
        // console.log(error);
      });
    console.log(msg, "msg");
    let userIndex = users.findIndex((user) => user.id === userId);
    const usersCopy = JSON.parse(JSON.stringify(users));
    const newMsgObject = {
      content: msg,
      sender: userId,
      time: new Date().toLocaleTimeString(),
      status: null,
    };
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    await axios
      .post(BASE_URL + "/message", { ...payload })
      .then(async function (response) {
        console.log(response.data, "responseresponse");
      })
      .catch(function (error) {
        console.log(error);
      });
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    setUsers(usersCopy);
    // return usersCopy;
    // });
  };
  const refresh = (newdata) => {
    // console.log("called", newdata);
    setUsers(newdata);
  };
  const checkLocation = async () => {
    setlocValid(true);
    // alert(userId, message);
    // let config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: `https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles/${loc_id}`,
    //   headers: {},
    // };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     setlocValid(true);
    //     // console.log(response.data);
    //   })
    //   .catch((error) => {
    //     setlocValid(false);

    //     // console.log(error, "jklsdhfjhfg");
    //   });
  };

  useEffect(() => {
    setUsers(data);
    checkLocation();
    // console.log(data, "calledcalledcalled");
  }, [data]);

  const setUserAsUnread = (userId) => {
    _updateUserProp(userId, "unread", 0);
  };

  const addNewMessage = async (userId, message, prompt) => {
    // console.log(prompt, message, "messagemessage");
    let userIndex = users.findIndex((user) => user.id === userId);
    var usersCopy = [...users];
    const newMsgObject = {
      content: message,
      sender: null,
      time: new Date().toLocaleTimeString(),
      status: "delivered",
    };
    // console.log(usersCopy[userIndex], "ksjdhf");
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    // console.log(usersCopy[userIndex])

    await axios
      .post(BASE_URL + "/message", { ...payload })
      .then(async function (response) {
        console.log(response.data, "responseresponse");
        if (response.data.chatupdated) {
          usersCopy[userIndex].name = message;
          setUsers(usersCopy);
        } else {
          setUsers(usersCopy);
        }
        if (prompt != undefined) {
          fetchMessageResponse({
            userId: userId,
            response: { content: prompt },
          });
        } else {
          fetchMessageResponse({
            userId: userId,
            response: { content: message },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        locValid,
        setlocValid,
        setUserAsUnread,
        addNewMessage,
        processing,
        refresh,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
