// import { Environment } from "../enums";

const env = {
  // API_URL : 'https://maybeapi.fixmyonline.com/api',
  // API_URL : 'http://35.176.217.108:8080/api'
  // http://localhost:8082
  // http://localhost:8082
  API_URL: "https://aiapi.fixmyonline.com/api",
};

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

// if (process.env.REACT_APP_ENV === Environment.STAGING) {
//   env.API_URL = "https://api.ghl.store/api";
// }

// if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
//   env.API_URL = "https://api.ghl.store/api";
// }

export default env;
